/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import config from '../../config.json';

export const Carousel = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [isAutoChangeOn, setIsAutoChangeOn] = useState(true);

  const length = slides.length;
  const { interval_sec } = config;

  useEffect(() => {
    if (isAutoChangeOn) {
      const timer = setTimeout(() => {
        nextSlide();
      }, interval_sec * 1000)
      return () => clearTimeout(timer)
    }
  }, [isAutoChangeOn, current])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [current, isAutoChangeOn])

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const handleMouseClick = (e) => {
    setIsAutoChangeOn(false);
    if (e.target.id === 'rightArrow') {
      nextSlide();
    } else {
      prevSlide();
    }
  }

  const handleKeyDown = (e) => {
    switch (e.code) {
      case 'Space': {
        if (isAutoChangeOn) {
          setIsAutoChangeOn(false)
        } else {
          nextSlide();
        }
        break;
      }

      case 'ArrowRight': {
        setIsAutoChangeOn(false);
        nextSlide();
        break;
      }

      case 'ArrowLeft': {
        setIsAutoChangeOn(false);
        prevSlide();
        break;
      }

      default:
        break;
    }
  }

  return (
    <section className='slider'>
      <FaArrowAltCircleLeft className='arrow left' onClick={handleMouseClick} id='leftArrow' />
      {/* {
        slides.map((slide, index) => {
          return (
            <div className={index === current ? 'slide active' : 'slide'} key={index}>
              {index === current && (
                <img src={slide} alt='travel' className='image' />
              )}
            </div>
          )
        })
      } */}
      <div className='wrapper'>
        <img src={slides[current]} className='image' alt='Midjourney vision' />
      </div>
      <FaArrowAltCircleRight className='arrow right' onClick={handleMouseClick} id='rightArrow' />
    </section>
  )
}
