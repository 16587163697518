import img1 from '../../images/carousel/ww1.png';
import img2 from '../../images/carousel/ww2.png';
import img3 from '../../images/carousel/ww3.png';
import img4 from '../../images/carousel/ww4.png';
import img5 from '../../images/carousel/ww5.png';
import img6 from '../../images/carousel/ww6.png';
import img7 from '../../images/carousel/ww7.png';
import img8 from '../../images/carousel/ww8.png';
import img9 from '../../images/carousel/ww9.png';
import img10 from '../../images/carousel/ww10.png';
import img11 from '../../images/carousel/ww11.png';
import img12 from '../../images/carousel/ww12.png';
import img13 from '../../images/carousel/ww13.png';
import img14 from '../../images/carousel/ww14.png';
import img15 from '../../images/carousel/ww15.png';
import img16 from '../../images/carousel/ww16.png';
import img17 from '../../images/carousel/ww17.png';
import img18 from '../../images/carousel/ww18.png';
import img19 from '../../images/carousel/ww19.png';
import img20 from '../../images/carousel/ww20.png';

export const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
];
