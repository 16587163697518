import { images } from '../Carousel/data';
import './App.css';
import { Carousel } from '../Carousel/Carousel';
import { shuffleArray } from '../../tools/shuffleArray';
import { useEffect, useState } from 'react';

function App() {
  const [slides, setSlides] = useState(null)

  useEffect(() => {
    setSlides(shuffleArray(images))
  }, [])

  if (slides === null) return null;

  return (
    <div className='app'>
      <h1 className='title'>Midjourney imagines&nbsp;webway :)</h1>
      <Carousel slides={slides} />
    </div>
  );
}

export default App;
